.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  border-color: #29302e !important;
  background-color: #29302e !important;
}

.react-multi-carousel-dot button {
  border-color: #9e9e9e !important;
  background-color: #9e9e9e !important;
}

.react-multi-carousel-dot-list {
  margin-top: 50;
}

.react-multi-carousel-list {
  height: 170px;
}

.react-multi-carousel-track {
  padding: 0 0 30px 0 !important;
  width: 165px;
}

.react-multi-carousel-item {
  min-width: 160px;
}
