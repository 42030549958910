.shadow-md {
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}
.navbar{
  border-radius: 5px;
}
.sidebar{
    width: 280px;
    min-height: 100vh;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background-color: #fff;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 1;
    transition: 0.5s;
}
.sidebar.active{
    left: 0;
    z-index: 2000;
}
.sd-header{
    border-bottom: 1px solid #e5e5e5;
    align-items: center;
    justify-content: space-between;
    padding: 38px 15px 16px 15px;
}
.sidebar-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}
.sidebar-overlay.active{
    opacity: 1;
    visibility: visible;
}

.btnCommission {
    min-width: 30px !important;
}

.modal-outstandingpayment .modal-footer {
    display: none;
}
