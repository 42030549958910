@media screen and (max-width: 1199.5px) {

    /* .summary-layout-drawer,
    .summary-layout-drawer .MuiBackdrop-root {
        margin-left: 356px;
    } */

    .summary-layout-drawer,
    .summary-layout-drawer .MuiBackdrop-root,
    .summary-layout-drawer .MuiPaper-root {
        top: 72px;
    }

    .summary-layout-drawer .MuiBackdrop-root {
        background-color: rgba(35, 31, 32, 0.3);
    }

    .summary-layout-drawer .MuiPaper-root {
        width: 100%;
        height: 100%;
        max-width: 320px;
        box-shadow: none;
    }
}

@media screen and (max-width: 899.5px) {

    /* .summary-layout-drawer,
    .summary-layout-drawer .MuiBackdrop-root {
        margin-left: 340px;
    } */
}

@media screen and (max-width: 767.5px) {

    /* .summary-layout-drawer,
    .summary-layout-drawer .MuiBackdrop-root {
        margin-left: 0;
    } */

    .summary-layout-drawer,
    .summary-layout-drawer .MuiBackdrop-root,
    .summary-layout-drawer .MuiPaper-root {
        top: 56px;
    }
}

@media screen and (max-width: 320px) {
    .summary-layout-drawer .MuiPaper-root {
        max-width: unset;
    }
}