.modal-popup-follow-treatment .modal-dialog {
  max-width: 832px;
  margin: auto;
}

.modal-popup-follow-treatment .modal-dialog .modal-body {
  color: #29302e;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-popup-follow-treatment .modal-dialog .modal-body .MuiTableContainer-root {
  overflow: auto;
  min-height: unset !important;
  max-height: 100%;
}

.modal-popup-follow-treatment .modal-dialog .modal-body .MuiTableContainer-root .MuiTable-root .MuiTableHead-root .MuiTableCell-root {
  position: sticky;
  top: 0;
  z-index: 5;
}

.modal-popup-follow-treatment .modal-dialog .modal-footer {
  margin-top: 0 !important;
}