body {
  font-family: 'Sarabun', sans-serif;
  font-size: 10px;
  -webkit-print-color-adjust: exact;
  margin: 0;
  color: #29302e;
}
p {
  margin: 0;
}
.mockup-a4-landscape {
  width: 842px;
  height: 695px;
  margin: auto;
}

.grid-item {
  display: grid;
  grid-template-columns: 14.28% 14.28% 14.28% 14.28% 14.28% 14.28% 14.28%;
  align-items: center;
}
.day {
  border: 1px solid;
  height: 90px;
  padding: 2px;
  overflow: hidden;
}
.day-text {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.day-number {
  float: right;
  font-size: 16px;
  font-weight: 500;
}
