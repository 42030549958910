.MuiOutlinedInput-notchedOutline {
  /* margin-top: 3px !important; */
  top: 0 !important;
}

.MuiOutlinedInput-notchedOutline legend {
  height: 0;
}

/**
 * Main wrapper
 */
.select-search {
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
  * Value wrapper
  */
.select-search__value {
  /* position: relative; */
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
  * Input
  */
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #fff;
  border: 1px solid transparent;
  /* box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15); */
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.MuiSelect-select.MuiSelect-outlined {
  border: unset;
}

.MuiSelect-root .MuiSelect-notchedOutline {
  border-color: #0000001f !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  background-color: #ffffff !important;
}

.MuiSelect-input {
  color: rgba(0, 0, 0, 0.6) !important;
}

.MuiSelect-root.Mui-focused .MuiSelect-input {
  color: rgba(0, 0, 0, 0.6) !important;
}

.MuiInputLabel-outlined {
  color: rgba(0, 0, 0, 0.6) !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  background-color: #fff;
}

.MuiMenuItem-root {
  font-size: 14px !important;
}

.ant-select-dropdown {
  z-index: 100000 !important;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
  * Options wrapper
  */
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
  * Options
  */
.select-search__options {
  list-style: none;
  padding: 0;
}

/**
  * Option row
  */
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
  * Option
  */
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  color: #fff;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  color: #fff;
}

/**
  * Group
  */
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
  * States
  */
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
  * Modifiers
  */
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

/* style-filter-select-search */
.style-filter-select-search-wrap.indexBelowSidebar {
  z-index: 1999 !important;
}

.style-filter-select-search {
  margin-top: 4px;
  border-radius: 4px !important;
  mix-blend-mode: multiply;
  background-blend-mode: multiply;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2) !important;
  background: linear-gradient(to bottom, #fff, #fff);
  height: auto;
}

.style-filter-select-search .MuiAutocomplete-option {
  padding: 0.5rem 1.5rem !important;
  color: #29302e;
}

.style-filter-select-search .MuiAutocomplete-option[aria-selected='true'],
.style-filter-select-search .MuiMenuItem-root.Mui-selected {
  background-color: transparent !important;
}

.Mui-multiple .MuiAutocomplete-option.Mui-focused {
  background-color: transparent !important;
  color: #29302e;
}

/* basic select */
.style-basic-select {
  max-height: 300px !important;
}

.style-filter-select-search .MuiMenuItem-root {
  font-size: 16px !important;
  display: flex !important;
  justify-content: flex-start !important;
  padding: 6px 16px !important;
  background-color: #fff !important;
}

.style-select-teeth {
  max-height: 200px !important;
}

.style-select-doctors {
  max-height: 200px !important;
  /* max-width: 160px !important; */
}

.style-select-doctors-mb {
  max-height: 200px !important;
  /* max-width: 240px !important; */
}
