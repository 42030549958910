.swal2-container {
  z-index: 2050 !important;
}

.swal-change-cost {
  z-index: 3000 !important;
}

/* backdrop */
.swal2-container.swal2-backdrop-show {
  background-color: rgba(35, 31, 32, 0.3) !important;
}

.swal2-container .swal2-popup {
  padding-bottom: 24px;
  backdrop-filter: saturate(1) blur(40px);
  background-color: rgba(255, 255, 255, 0.9);
  width: auto;
  max-width: 576px;
  border-radius: 10px;
}

.swal2-container.swal2-warning .swal2-close {
  box-shadow: none !important;
  outline: none !important;
  font-size: 30px;
  color: #7070709c;
}

.swal2-container.swal2-warning .swal2-close:hover {
  color: #707070;
}

.swal2-container .swal2-icon.swal2-icon-show {
  margin: 24px auto;
}

.swal2-container .swal2-title {
  font-weight: 500;
  color: #29302e;
  padding: 0 24px;
  line-height: 1.3;
  font-size: 24px;
}

.swal2-container .swal2-html-container {
  font-size: 16px;
  color: #6d6e71;
  margin: 0;
  padding: 8px 24px 0;
  line-height: 1.5;
}

.swal2-container .swal2-actions {
  margin: 24px 0 0;
}

.swal2-container .swal2-actions button {
  margin: 0 8px;
  box-shadow: none !important;
  border: 1px solid;
}

/* swal2-cancel button */
.swal2-container .swal2-actions button.swal2-cancel {
  background-color: #ffffff;
  border-color: #929292;
  color: #29302e;
  height: 45px;
}

.swal2-container .swal2-actions button.swal2-cancel:hover {
  background: rgba(146, 146, 146, 0.03);
  border-color: #929292;
}

.swal2-container .swal2-cancelTreatmentQueue {
  background-color: #fb5252 !important;
  border-color: #fb5252 !important;
  color: #ffffff !important;
}
