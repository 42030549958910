.modal-confirm-appointment .modal-content {
  margin: auto;
}

.modal-confirm-appointment.modal-confirm-appointment-submit .modal-content {
  max-width: 418px;
}

.modal-confirm-appointment.modal-confirm-appointment-cancel .modal-content {
  max-width: 500px;
}