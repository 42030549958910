.modal-form-appointment .modal-xl .modal-footer {
  margin-left: 4px;
  margin-right: 4px;
}

@media screen and (min-width: 768px) {
  .modal-form-appointment .modal-xl .modal-footer {
    margin-left: 8px;
    margin-right: 8px;
  }
}

@media screen and (min-width: 992px) {
  .modal-form-appointment .modal-xl {
    max-width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .modal-form-appointment .modal-xl {
    max-width: 1366px;
  }
}
