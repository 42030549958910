:root {
  --theme-main-color: #2f4269;
  --theme-second-color: #1cb99a;
  --theme-third-color: #d8e8f4;
  --theme-fourth-color: #fb5252;
  --theme-fifth-color: #900c3f;
  --pale-blue: #d8e8f4;
  --theme-fight-color: #1cb99a;
  --theme-sixth-color: #ffeded;
  --theme-reset-color: #fc8f8f;
  --navbar-height: 70px;
  --sidebar-width: 70px;
}

/* FONT */
.fz-14 {
  font-size: 14px;
}
.fz-16 {
  font-size: 16px;
}
.fw-700 {
  font-weight: 700;
}
.fs-dot75rem {
  font-size: 0.75rem;
}

.fc-main {
  color: var(--theme-main-color);
}
.c-bbbbbb {
  color: #BBBBBB !important;
}

.link-simple {
  cursor: pointer;
  text-decoration: underline;
}
.text-nowrap-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wp-30 {
  width: 30px;
}
.wp-500 {
  width: 500px;
}

.hard-break {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}
.cursor-pointer {
  cursor: pointer;
}

img.flip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

/* LAYOUT */
@media (min-width: 1920px) {
  .order-3xl-1 {
    order: 1 !important;
  }
  .order-3xl-2 {
    order: 2 !important;
  }
  .col-3xl,
  .col-3xl-auto,
  .col-3xl-1,
  .col-3xl-2,
  .col-3xl-3,
  .col-3xl-4,
  .col-3xl-5,
  .col-3xl-6,
  .col-3xl-7,
  .col-3xl-8,
  .col-3xl-9,
  .col-3xl-10,
  .col-3xl-11,
  .col-3xl-12 {
    flex: 0 0 auto;
  }
  .col-3xl-auto {
    width: auto;
  }
  .col-3xl-1 {
    width: 8.33333333%;
  }
  .col-3xl-2 {
    width: 16.66666667%;
  }
  .col-3xl-3 {
    width: 25%;
  }
  .col-3xl-4 {
    width: 33.33333333%;
  }
  .col-3xl-5 {
    width: 41.66666667%;
  }
  .col-3xl-6 {
    width: 50%;
  }
  .col-3xl-7 {
    width: 58.33333333%;
  }
  .col-3xl-8 {
    width: 66.66666667%;
  }
  .col-3xl-9 {
    width: 75%;
  }
  .col-3xl-10 {
    width: 83.33333333%;
  }
  .col-3xl-11 {
    width: 91.66666667%;
  }
  .col-3xl-12 {
    width: 100%;
  }
  .mt-3xl-0 {
    margin-top: 0px !important;
  }
}
