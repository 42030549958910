@font-face {
  font-family: 'Sarabun, sans-serif';
  src: local('Sarabun, sans-serif'), url(./assets/fonts/Sarabun//Sarabun-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Sarabun, sans-serif';
  src: local('Sarabun, sans-serif'), url(./assets/fonts/Sarabun//Sarabun-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Sarabun, sans-serif';
  src: local('Sarabun, sans-serif'), url(./assets/fonts/Sarabun//Sarabun-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Sarabun, sans-serif';
  src: local('Sarabun, sans-serif'), url(./assets/fonts/Sarabun//Sarabun-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

body {
  margin: 0;
  font-family: 'Sarabun, sans-serif', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Sarabun, sans-serif', sans-serif !important;
  letter-spacing: 0.5px;
}

code {
  font-family: 'Sarabun, sans-serif', monospace;
}

*[class*='jss'],
*[class*='jss']:hover {
  color: unset !important;
  background-color: unset !important;
  /* z-index:auto ; */
}
