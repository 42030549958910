.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: transparent;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: transparent;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: transparent;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root,
.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  height: 100%;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  margin: -1em !important;
  padding-left: 1em !important;
}

a.mr-3.p-0.w-100 {
  text-decoration: none !important;
}
