/* modal-treatment */
.modal-treatment .modal-dialog .modal-content .modal-body {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
  margin-bottom: 0 !important;
}

.modal-treatment .modal-dialog .modal-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.modal-treatment .modal-dialog .modal-content .modal-footer {
  margin-top: 0 !important;
}

.modal-treatment.modal-treatment-info .modal-dialog .modal-content {
  padding-bottom: 0 !important;
}

/* modal-treatment-course */
.modal-treatment-course .modal-content {
  max-width: 1149px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden !important;
}

.modal-treatment-course.disabled-modal-footer .modal-footer button {
  border-color: #9e9e9e !important;
  color: #9e9e9e !important;
  pointer-events: none;
}

.modal-treatment-course.disabled-modal-footer .modal-footer button.btn-submit {
  background-color: #9e9e9e !important;
  color: #fff !important;
}

/* modal-next-visit */
.modal-next-visit .modal-content {
  max-width: 1072px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden !important;
}

.modal-next-visit .modal-content .modal-footer {
  display: none !important;
}

/* modal-med-certificate */
.modal-med-certificate .modal-fullscreen {
  height: auto !important;
}

.modal-med-certificate .modal-content {
  max-width: 1040px;
  margin: auto;
  height: auto !important;
}

.modal-med-certificate .modal-content table th {
  position: sticky;
  top: 0;
  z-index: 5;
}

/* modal-alert-out-of-stock */
.modal-alert-out-of-stock .modal-content {
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
}

/* modal-treatment-consent */
.modal-treatment-consent .modal-content .modal-footer {
  margin-top: 0 !important;
}

/* modal-treatment-message */
.modal-treatment-message .modal-dialog .modal-content .modal-body {
  margin-bottom: 24px;
}

/* modal-treatment */
/* modal-treatment-course */
/* modal-treatment-message */
.modal-treatment-course .modal-dialog .modal-content .modal-body,
.modal-treatment-message .modal-dialog .modal-content .modal-body,
.modal-med-certificate .modal-dialog .modal-content .modal-body {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
}

/* modal-treatment */
/* modal-treatment-message */
/* modal-next-visit */
.modal-treatment .modal-dialog .modal-content,
.modal-treatment-message .modal-content,
.modal-next-visit .modal-content {
  overflow: hidden !important;
}

/* modal-emr-overview */
.modal-emr-overview .modal-dialog {
  min-height: 100%;
  width: 100%;
  max-width: unset;
  padding: 1rem;
  margin: auto;
}

.modal-emr-overview .modal-content {
  max-width: 1512px;
  margin: auto;
}

.modal-alert-out-of-stock .modal-content .modal-footer,
.modal-emr-overview .modal-content .modal-footer {
  margin-top: 0 !important;
}

/* modal-txc */
.modal-txc .modal-content {
  max-width: 1600px;
  margin: auto;
}

.modal-txc .modal-body {
  overflow-x: hidden !important;
}

/* popup-manage-product  */
.popup-manage-product .modal-dialog {
  max-width: 1235px;
}

/* recommend */
.modal-recommend .modal-dialog .modal-content {
  max-width: 1700px;
  margin: auto;
}

/* 
modal-popup-select-product
popup-manage-product
*/
.popup-manage-product .modal-dialog .modal-content,
.modal-popup-select-product .modal-dialog .modal-content {
  margin: 1rem;
}

@media screen and (min-width: 1400px) {

  /* modal-treatment-message */
  .modal-treatment-message .modal-dialog .modal-content {
    min-height: 50%;
  }

  /* modal-treatment */
  /* modal-treatment-course */
  /* modal-treatment-message */
  .modal-treatment .modal-dialog,
  .modal-treatment-course .modal-dialog,
  .modal-treatment-message .modal-dialog {
    max-height: calc(100% - 3.5rem);
    height: 100%;
  }

  .modal-treatment .modal-dialog .modal-content,
  .modal-treatment-course .modal-dialog .modal-content,
  .modal-treatment-message .modal-dialog .modal-content {
    max-height: 100%;
  }
}

/* modal-pdf-referral-examination */
.modal-referral-examination .modal-content {
  max-width: 1140px;
  margin: auto;
}

@media (max-width: 1200px) {
  .modal-referral-examination .modal-content {
    max-width: 900px;
  }
}

@media (max-width: 992px) {
  .modal-referral-examination .modal-dialog {
    max-width: 650px;
  }
}