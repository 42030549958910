.form-switch .form-check-input {
  height: 25px !important;
  width: 48px !important;
  cursor: pointer;
  background-color: #9e9e9e !important;
  border-color: #9e9e9e !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}

.form-switch .form-check-input:checked {
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.form-switch .form-check-input:focus {
  box-shadow: none !important;
}

.form-switch .form-check-input:focus:not(:checked) {
  border-color: #9e9e9e !important;
}
