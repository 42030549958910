.add-icon {
  cursor: pointer;
  margin-left: auto;
}

.delete-icon {
  color: #fff;
  width: 10px;
  height: 10px;
  cursor: pointer;
  margin-left: auto;
}
