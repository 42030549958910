.pd-body {
  padding: 10px;
  height: 220px;
  border-radius: 5px;
  overflow: scroll;
  scrollbar-width: thin;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  /* scrollbar-width: none; */
  /* Firefox */
}

.pd-body::-webkit-scrollbar {
  display: none;
}

.pd-body-unit {
  overflow: hidden;
  height: 198px;
  width: 120px;
  float: left;
  margin-right: 12px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.unit-img-contain {
  width: 100%;
  height: 85%;
  margin-bottom: 4px;
  position: relative;
}

.unit-img-bg-text {
  width: 100%;
  background-color: #21252996;
  color: #fff;
  position: absolute;
  z-index: 10;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  bottom: 1px;
  font-size: 14px;
  padding: 0px 5px;
}

.unit-card-title-img {
  background-color: aqua;
  bottom: 7px;
  left: 50%;
  /* left: 16px; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-size: 0.875rem;
  position: absolute;
}

.unit-img-contain .unit-img {
  width: 100%;
  height: 100%;
}

.original-price {
  padding: 0px 5px;
  display: flex;
  width: 100%;
  font-size: 14px;
  color: #000000;
}

.add-icon {
  cursor: pointer;
  margin-left: auto;
}

.delete-icon {
  color: #fff;
  width: 10px;
  height: 10px;
  cursor: pointer;
  margin-left: auto;
}

@media only screen and (max-width: 768px) {
  .pd-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    height: 300px;
    border: 1px solid black;
    border-radius: 5px;
    overflow: scroll;
  }

  .fs-body-unit {
    width: 100px;
    height: auto;
    margin-right: 0px;
  }

  .unit-img-contain {
    width: 100px;
    height: 100px;
  }

  .unit-img-contain .unit-img {
    width: 100px;
    height: 100px;
  }

  .unit-card-original-price {
    font-size: 10px;
  }
}
