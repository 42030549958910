.modal-form-management {
  padding-left: 0 !important;
}

.modal-form-management .modal-content {
  max-width: 976px;
  margin: auto;
}

.modal-form-management .modal-content .modal-body {
  padding-top: 0;
}

.modal-form-management .modal-content .modal-body .row {
  margin-left: 0;
  margin-right: 0;
}

.modal-form-management .modal-content .modal-body .header-tab {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  border-radius: 4px;
}

.modal-form-management .modal-content .modal-body .row-menu {
  padding-left: 1rem;
  padding-right: 1rem;
}

.modal-form-management .modal-content .modal-body .row-menu:last-of-type {
  margin-bottom: -9px;
}