@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.slick-default .slick-track {
  margin-left: 0 !important;
}

.slick-default .slick-dots {
  position: relative;
  bottom: 0;
  margin-top: 1.5rem;
  line-height: 0;
}

.slick-default .slick-dots li {
  width: auto;
  height: auto;
  margin: 0 0.5rem;
}

.slick-default .slick-dots li.slick-active button {
  background-color: #29302e;
}

.slick-default .slick-dots li button {
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background-color: #9e9e9e;
}

.slick-default .slick-dots li button:before {
  display: none;
}

.slick-default .slick-arrow {
  width: 35px;
  height: 35px;
  transform: translateY(-32px);
  background-color: #e5e5e5 !important;
  border-radius: 100px;
  text-align: center;
  z-index: 1;
}

.slick-default .slick-arrow:before {
  font-family: 'Material Icons', sans-serif !important;
  font-size: 27px;
  font-weight: bold;
  color: #fff;
  opacity: 1;
}

.slick-default .slick-arrow.slick-prev {
  left: -10px;
}

.slick-default .slick-arrow.slick-prev:before {
  content: 'chevron_left';
}

.slick-default .slick-arrow.slick-next {
  right: -10px;
}

.slick-default .slick-arrow.slick-next:before {
  content: 'chevron_right';
}

.slick-default .slick-arrow.slick-disabled {
  visibility: hidden;
}

.slick-default .slick-next.slick-disabled + .slick-dots li:last-of-type button {
  background-color: #29302e;
}

@media screen and (max-width: 1199px) {
  .slick-default .slick-arrow.slick-prev {
    left: 0;
  }

  .slick-default .slick-arrow.slick-next {
    right: 0;
  }
}

@media screen and (max-width: 767px) {
  .slick-default .slick-dots {
    margin-top: 0.75rem;
  }

  .slick-default .slick-dots li {
    margin: 0 0.25rem;
  }

  .slick-default .slick-dots li button {
    width: 8px;
    height: 8px;
  }

  .slick-default .slick-arrow {
    display: none !important;
    visibility: hidden;
  }
}
