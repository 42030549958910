#print-table-container {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

@media print {
  @page {
    /* size: landscape; */
    margin: 5mm;
    /* height: 90%; */
  }
  .private-route-container {
    visibility: hidden;
  }

  #print-table-container {
    visibility: visible ;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  /* table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    page-break-inside: auto;
} */

tr {
    page-break-inside: avoid; /* ป้องกันไม่ให้แถวถูกตัดระหว่างหน้า */
}

thead {
    display: table-header-group; /* ทำให้ส่วนหัวซ้ำบนทุกหน้า */
}

tfoot {
    display: table-footer-group; /* ทำให้ส่วนท้ายซ้ำบนทุกหน้า */
}

/* บังคับ table 2 ให้ขึ้นหน้าใหม่ */
.table-2 {
    page-break-before: always; /* เริ่มหน้าใหม่ก่อนตารางนี้ */
    break-before: page; /* สำหรับเบราว์เซอร์ที่รองรับ break-* */
}

/* ขึ้นหน้าใหม่ เมืื่อหน้าไม่พอ */
.page-break-inside {
  display: block;
  page-break-inside: avoid;
}
 
}

body {
  overflow: hidden;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column !important;
}

.root {
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: #f9fafc !important;
  font-size: 16px;
}

.ml {
  margin-left: 65px;
}

.container-menu {
  color: black;
  background-color: #f2f2f296 !important;
  z-index: 2000 !important;
}

.div-component {
  flex: 1;
  overflow: auto;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.w-max {
  width: max-content !important;
}

.h-vh-100 {
  height: 100vh;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.d-block {
  display: block;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.wp-150 {
  width: 150px !important;
}

*::selection {
  color: unset;
}

div[class$='jss'],
div[class$='jss']:hover {
  color: unset !important;
  background-color: unset !important;
}

.tooltip-graph-appointment {
  z-index: 1 !important;
}

.tooltip-graph-appointment .MuiTooltip-tooltip {
  color: #929292 !important;
  background-color: transparent !important;
  margin-bottom: 0 !important;
}
