.btn-delete {
  background-color: #ff0000;
  color: #ffffff;
  cursor: 'pointer';
}

a.link-nav {
  color: #000000;
  text-decoration: unset;
}

a.link-nav:hover {
  text-decoration: underline;
}

.MuiFormControl-root {
  min-width: 200px;
}
