.print-show {
    display: none;
  }
  
  body > .print-show {
    display: block;
  }
  
  @media print {
    .print-show {
      display: block;
    }
  }

  .print-show-disable {
    display: block;
  }
  
  body > .print-show-disable {
    display: block;
  }
  
  @media print {
    .print-show-disable {
      display: none;
    }
  }