.modal-cancel-visit .modal-content {
    max-width: 543px;
    margin: auto;
}

.modal-send-line-message .modal-dialog {
    max-width: 1140px;
    margin: auto;
}

@media screen and (min-width: 1200px) {
    .modal-send-line-message .modal-dialog .modal-content {
        max-height: calc(100% - 5rem);
    }
}

@media screen and (max-width: 767.5px) {
    .modal-send-line-message .modal-body {
        margin-bottom: 0 !important;
    }

    .modal-send-line-message .modal-footer {
        display: none !important;
    }
}

.modal-xray .modal-dialog{
    margin: auto;
    padding: 1rem 3.2rem !important;
}