.modal-receive-lab .modal-dialog {
  padding: 8px;
}

.modal-receive-lab .modal-content {
  max-width: 543px;
  margin-left: auto;
  margin-right: auto;
}

.modal-receive-lab .modal-content .modal-footer > div > div {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.modal-receive-lab .modal-content .modal-footer > div > div button {
  margin: 0 !important;
}

@media screen and (max-width: 576px) {
  .modal-receive-lab .modal-content .modal-footer > div {
    flex-direction: column;
  }

  .modal-receive-lab .modal-content .modal-footer > div > div {
    width: 100%;
  }

  .modal-receive-lab .modal-content .modal-footer > div > div:last-of-type {
    padding-top: 16px;
  }
}
