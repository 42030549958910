.Toastify__toast {
  min-height: auto;
  min-width: 380px;
  mix-blend-mode: multiply;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.12);
  background-blend-mode: multiply;
  background: linear-gradient(to bottom, #fff, #fff);
  padding: 12px 16px;
  margin-right: 1rem;
}

.Toastify__toast-container {
  z-index: 2000;
}

.Toastify__toast-icon {
  margin-right: 16px;
  width: 24px;
}

.Toastify__close-button {
  line-height: 1.3;
  margin-left: 10px;
}

/* success */
.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--success {
  border-left: 4px solid #20a679 !important;
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--success .Toastify__toast-icon svg {
  fill: #20a679;
}

/* error */
.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error {
  border-left: 4px solid var(--toastify-color-error) !important;
}

/* info as warning */
.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--info {
  border-left: 4px solid #f88f41 !important;
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--info .Toastify__toast-icon svg {
  fill: #f88f41;
}

.Toastify__toast .Toastify__toast-body {
  margin: 0;
  padding: 0;
  color: #29302e;
}

.Toastify__toast .Toastify__progress-bar {
  height: 1px;
  opacity: 0 !important;
}

@media screen and (max-width: 576px) {
  .Toastify__toast {
    min-width: 100%;
    margin: 0;
  }

  .Toastify__toast-container {
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
  }
}

/* specific */
.Toastify__toast.register-page {
  margin-bottom: 72px;
}

.Toastify__toast.register-page + .Toastify__toast.register-page {
  margin-top: -56px;
}

/* case popup */
.Toastify__toast-container.Toast-popup {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  transform: unset;
  padding: 0;
  width: auto;
}

.Toastify__toast-container.Toast-popup .Toastify__toast {
  margin: 8px 0 0;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
}

.Toastify__toast-container.Toast-popup .Toastify__close-button {
  color: #bcbec0;
  opacity: 1;
  line-height: normal;
}

.Toastify__toast-container.Toast-popup .Toastify__close-button svg {
  width: 20px;
  height: 20px;
}

/* toast popup custom */
.Toastify__toast-container.Toast-popup .toast-custom {
  cursor: default;
}

/* animate */
@keyframes slide_in {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide_out {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-50%);
    opacity: 0;
  }
}

.slide-in {
  animation: slide_in 0.3s ease-out both;
}

.slide-out {
  animation: slide_out 0.3s ease-in both;
}
