.promotion-del .swal2-warning{
  border-color: #FB5252 !important;
  color: #FB5252 !important;
}

.promotion-del-con {
  border-color: #FB5252 !important;
  background-color: #FB5252 !important;
}

.btn-check-in-swal {
  color: #1cb99a !important;
  border-color: #1cb99a !important ;
}
