@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
@import "@pdftron/webviewer-react-toolkit";

/* level 1 */
.container-menu .menu-first {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2000;
  background: linear-gradient(177deg, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 242, 1) 100%);
  transition: max-width 0.3s ease;
  border: 1px solid #e5e5e5;
  border-radius: 0 0.5rem 0.5rem 0;
  padding: 16px 0;
}

.container-menu .menu-first.show {
  z-index: 2000;
  border-top-right-radius: 0;
}

.container-menu .menu-first.show:before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(171deg, rgba(255, 255, 255, 1) 1%, rgba(242, 242, 242, 1) 99%);
  backdrop-filter: saturate(1) blur(20px);
  -webkit-backdrop-filter: saturate(1) blur(20px);
  z-index: -1;
}

.menu-first .menu-first-level .flex-name {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.375rem 1.5rem;
  margin: 0.25rem 0;
  cursor: pointer;
}

.menu-first .menu-first-level .flex-name.menu-first-arrow::after {
  content: 'arrow_right';
  font-family: 'Material Icons Round', sans-serif;
  margin-left: auto;
  margin-right: -8px;
  font-size: 22px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.menu-first.show .menu-first-level .flex-name.menu-first-arrow:hover::after,
.menu-first.show .menu-first-level .flex-name.menu-first-arrow.active::after {
  opacity: 1;
}

.menu-first .menu-first-level .flex-name.active .menu-first-text {
  font-weight: 600;
}

.menu-first .menu-first-level .menu-first-text {
  padding-left: 16px;
  display: none;
}

.menu-first .menu-first-level .menu-first-icon.active {
  position: relative;
  z-index: 0;
}

.menu-first .menu-first-level .menu-first-icon {
  background-color: transparent !important;
}

.menu-first .menu-first-level .menu-first-icon.active::before {
  content: '';
  position: absolute;
  top: -8px;
  bottom: -8px;
  left: -8px;
  right: -8px;
  border-radius: 100px;
  z-index: -1;
  transition: background-color 0.3s ease;
}

.menu-first.show .menu-first-level .flex-name .menu-first-icon::before,
.menu-first .menu-first-level .flex-name:hover .menu-first-icon.active::before,
.menu-first .menu-first-level .flex-name.active .menu-first-icon.active::before {
  background-color: transparent;
}

.menu-first.show .menu-first-level .menu-first-text {
  display: block;
  color: #231f20;
}

/* level 2 */
.menu-second {
  display: none;
  position: absolute;
  /* top: auto; */
  /* bottom: 0; */
  left: 100%;
}

.menu-second.active {
  display: block;
}

.menu-second .menu-second-wrap {
  padding: 1rem 0 1.25rem;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(242, 242, 242, 1));
  backdrop-filter: saturate(1) blur(35px);
  -webkit-backdrop-filter: saturate(1) blur(35px);
  border: 1px solid #e5e5e5;
  border-radius: 0 0.5rem 0.5rem 0;
}

.menu-second .menu-second-level {
  position: relative;
}

.menu-second .menu-second-level .item-second-list {
  position: unset;
  margin: 0.25rem 0;
  padding: 0.5785rem 1rem;
  justify-content: space-between;
  text-align: left;
  font-size: 16px;
  font-weight: 400 !important;
  cursor: pointer;
  color: #231f20;
}

.menu-second .menu-second-level .item-second-list svg {
  font-size: 24px;
}

.menu-second .menu-second-level .item-second-list[aria-expanded='true'] span {
  font-weight: 600;
}

/* level3 as Collapse */
.menu-third .menu-third-level {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu-third .menu-third-level .item-third-list {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 24px;
  margin: 0.25rem 0;
}

.menu-third .menu-third-level .item-third-list::before {
  content: 'fiber_manual_record';
  font-family: 'Material Icons', sans-serif !important;
  font-size: 11px;
  margin-right: 0.5rem;
}

.menu-first .menu-first-wrap {
  overflow-x: hidden;
}

.menu-first .menu-first-wrap,
.menu-second .menu-second-wrap {
  overflow-y: auto;
  scrollbar-width: thin;
}

.menu-first .menu-first-wrap::-webkit-scrollbar,
.menu-second .menu-second-wrap::-webkit-scrollbar {
  width: 0.25rem;
}

.menu-first .menu-first-wrap::-webkit-scrollbar-thumb,
.menu-second .menu-second-wrap::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
  border-radius: 50px;
}

/* backdrop */
.sidebar-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #231f20;
  opacity: 0.3;
  z-index: 1999;
}

/* tooltip */
.customTooltip-sidebar[data-title]:before {
  content: attr(data-title);
  color: transparent !important;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  font-weight: 300;
  font-size: 14px;
  display: none;
  background-color: transparent;
  padding: 3px 6px;
  border-radius: 4px;
  line-height: normal;
}

.customTooltip-sidebar[data-title]:after {
  content: attr(data-title);
  color: #fff !important;
  position: absolute;
  top: 100%;
  /* left: 0; */
  right: 0;
  /* width: max-content; */
  font-weight: 300;
  font-size: 14px;
  display: none;
  background-color: #444;
  padding: 3px 6px;
  border-radius: 4px;
  line-height: normal;
}

.menu-second .menu-second-level button.item-second-list .customTooltip-sidebar[data-title]:after {
  top: 51px;
}

@media screen and (max-width: 1199.5px) {
  .customTooltip-sidebar[data-title]:after {
    display: none !important;
  }

  .container-menu .menu-first.show:before,
  .menu-second .menu-second-wrap {
    background: #f5f5f5;
  }
}

@media screen and (min-width: 1200px) {
  /* show tooltip */
  .menu-first .menu-first-level .flex-name:hover .customTooltip-sidebar[data-title]:before,
  .menu-second .menu-second-level button.item-second-list:hover .customTooltip-sidebar[data-title]:before,
  .menu-third .menu-third-level .item-third-list:hover .customTooltip-sidebar[data-title]:before,
  .menu-first .menu-first-level .flex-name:hover .customTooltip-sidebar[data-title]:after,
  .menu-second .menu-second-level button.item-second-list:hover .customTooltip-sidebar[data-title]:after,
  .menu-third .menu-third-level .item-third-list:hover .customTooltip-sidebar[data-title]:after {
    display: block;
  }
}
