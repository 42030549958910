.month-indicator {
  color: var(--blue-grey-700);
  text-align: center;
  font-weight: 500;
}

.day-of-week,
.date-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.day-of-week {
  margin-top: 1.25em;
}

.day-of-week > * {
  font-size: 1.1vw;
  font-size: 0.7em;
  color: var(--blue-grey-400);
  font-weight: 500;
  letter-spacing: 0.1em;
  font-variant: small-caps;
  text-align: center;
}

/* Dates */
.date-grid {
  margin-top: 0.5em;
}

/* Positioning the first day */
.date-grid button:first-child {
  grid-column: 6;
}

.date-grid button {
  position: relative;
  border: 0;
  width: 4.5ch;
  height: 4.5ch;
  border-radius: 50%;
  background-color: transparent;
  color: var(--blue-grey-600);
}

.date-grid button:active,
.date-grid button.is-selected {
  background-color: var(--teal-100);
  color: var(--teal-900);
}

.calendar-root {
  --grid-gap: 2px;
  --grid-height: auto;
  --text-color-light: rgba(0, 0, 0, 0.4);
  --text-color-primary: rgba(0, 0, 0, 0.7);
  --space-sm: 4px;
  --space-md: 8px;
  --space-lg: 16px;
  width: 100%;
}

/* | Sun | Mon | Tue | Wed | Thu | Fri | Sat  */
.day-of-week-header-cell {
  color: #2f4269;
  font-weight: 700;
  /* background-color: var(--grid-foreground-color); */
  padding: var(--space-md) 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* border: 1px solid black; */
  /* border-radius: 20px; */
  text-align: center;
  background-color: #d8e8f4;
}

.day-of-week-header-cell .weekend-day {
  border-radius: 20px;
}

.days-of-week {
  font-size: 1.1vw;
  width: fit-content;
  box-sizing: border-box;
  display: grid;
  /* grid-template-columns: repeat(7, 12.8vw); */
  grid-template-columns: repeat(7, 13vw);
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
  /* border: 1px solid black; */
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
}
@media screen and (max-width: 767.5px) {
  .days-of-week {
    font-size: 1rem;
    grid-template-columns: repeat(7, 23vw);
  }
}

.days-of-week-edit {
  font-size: 1.1vw;
  margin-bottom: 16px;
  cursor: pointer;
  width: fit-content;
  box-sizing: border-box;
  display: grid;
  /* grid-template-columns: repeat(7, 12.8vw); */
  grid-template-columns: repeat(7, 13vw);
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
  /* border: 1px solid black; */
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
}

@media screen and (max-width: 767.5px) {
  .days-of-week-edit {
    font-size: 1rem;
    grid-template-columns: repeat(7, 23vw) !important;
  }
}

.day-of-week-header-cell-edit {
  /* color: #2f4269; */
  font-weight: 700;
  /* background-color: var(--grid-foreground-color); */
  padding: var(--space-md) 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 20px;
  text-align: center;
  background-color: #fff;
}

.days-grid {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  /* grid-template-columns: repeat(7, 12.8vw); */
  grid-template-columns: repeat(7, 13vw);
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
  /* border: 1px solid black; */
  /* border-radius: 20px 20px 0px 0px; */
  /* overflow: hidden; */
  height: var(--grid-height);
  position: relative;
  /* border: var(--grid-gap) solid var(--grid-background-color); */
  background-color: var(--grid-background-color);
}

@media screen and (max-width: 767.5px) {
  .days-grid {
    grid-template-columns: repeat(7, 23vw);
  }
}

.day-grid-item-container {
  background-color: white;
  height: 160px;
  /* overflow: hidden; */
  /* position: relative; */
  /* background-color: var(--grid-foreground-color); */
  border: 1px solid #d8e8f4;
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
}
.day-grid-item-container:hover {
  overflow: visible;
}

.day-grid-item-container:not(.current-month) .day-grid-item-header {
  color: #9e9e9e;
}

/* Position the day label within the day cell */
.day-grid-item-container .day-grid-item-header {
  line-height: 19px;
  font-size: 12px;
  width: 24px;
  height: 24px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  color: #29302e;
  /* padding-left: 8px;
  padding-bottom: 0; */
  flex-shrink: 0;
  font-weight: bold;
  text-align: center;
  background-color: #fff;
}

.day-grid-item-container > .day-content-wrapper {
  flex: 1;
  min-height: 0;
  position: relative;
}

.navigation-header {
  display: flex;
}

.navigation-header > * {
  margin-right: var(--space-md);
}

.header-days {
  padding: 3px;
  width: fit-content;
  border: 1px solid black;
  border-radius: 4px;
  font-size: 16px;
}

.header-item {
  display: flex;
  align-items: baseline;
}

.trash-icon {
  cursor: pointer;
  margin-left: auto;
}

.container-item {
  height: 100%;
  padding: 8px 8px;
}
.body-item {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.container-item-edit {
  height: 100%;
  padding: 8px 8px;
}
.dentist-container {
  align-items: center;
  display: flex;
  cursor: pointer;
}

.img-container {
  width: 2vw;
  height: 2vw;
  background-color: black;
  border-radius: 50%;
  margin-right: 8px;
}
.text-container {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dentist-header {
  display: flex;
  align-items: center;
  margin: 8px 0px 4px 0px;
}
.dentist-name {
  font-size: 1.1vw;
  color: #2f4269;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dentist-time {
  font-size: 0.9vw;
  color: rgba(5, 5, 5, 0.6);
  margin-left: 4px;
}

.more-time {
  font-size: 0.9vw;
  text-align: end;
}
.dropdown-all-dentist {
  left: 0;
  display: none;
  position: absolute;
  background-color: #ffffff !important;
  width: 12.8vw;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.text-container-single {
  width: 100%;
}

.dentist-header-single {
  display: flex;
  align-items: flex-start;
  margin: 8px 0px 4px 0px;
}
.dentist-name-single {
  width: 80%;
  font-size: 1.1vw;
  color: #2f4269;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dentist-time-single {
  font-size: 0.8vw;
  color: rgba(5, 5, 5, 0.6);
  margin-left: 4px;
}
.dentist-assistant {
  font-size: 0.8vw;
  display: flex;
  margin-left: 4px;
}
.title-assistant {
  white-space: nowrap;
  margin-right: 2px;
}

.worng-duty {
  font-size: 0.9vw;
  color: #e2a706;
}

.cancel-duty {
  font-size: 0.9vw;
  color: #fb5252;
}

.item-worng-duty {
  background-color: aqua;
}
.item-cancel-duty {
  background-color: aqua;
}
.icon-time {
  margin-right: 6px;
}
.icon-assistant {
  margin-right: 5px;
}
.icon-bank {
  margin-right: 6px;
}

.dentist-cost-condition {
  font-size: 0.8vw;
  margin-left: 4px;
}

.container-item-worng-duty {
  align-items: center;
  justify-items: center;
  height: 100%;
  background-color: rgba(251, 82, 82, 0.1);
  padding: 8px 8px;
}

.dentist-container-worng-duty {
  font-size: 18px;
  margin-top: 32px;
  height: auto;
  text-align: center;
}

.container-item-cancel-duty {
  align-items: center;
  justify-items: center;
  height: 100%;
  background-color: rgba(226, 167, 6, 0.1);
  padding: 8px 8px;
}

.dentist-container-cancel-duty {
  font-size: 18px;
  margin-top: 32px;
  height: auto;
  text-align: center;
}

.input .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  height: 32px;
  background-color: #fff;
}

.closeButton {
  position: absolute;
  top: 20;
  right: 20;
}

.modal .modal-content {
  padding: 15;
  border-radius: 10;
  position: relative;
}

.text-container-modal {
  width: 80%;
}

.date-model {
  font-size: 1.1vw;
  font-weight: 700;
  margin-bottom: 0.6vw;
}
.dentist-header-modal {
  white-space: nowrap;
  display: flex;
  align-items: flex-start;
  margin: 8px 0px 4px 0px;
}
.dentist-name-modal {
  width: 80%;
  font-size: 1.1vw;
  color: #2f4269;
  font-weight: 700;
}
.dentist-time-modal {
  font-size: 1vw;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 4px;
  /* text-overflow: ellipsis;
  overflow: hidden; */
}
.dentist-assistant-modal {
  font-size: 1vw;
  display: flex;
  margin-left: 4px;
}

.title-assistant-modal {
  /* width: 40%; */
  white-space: nowrap;
  margin-right: 2px;
}
.dentist-cost-condition-modal {
  font-size: 1vw;
  margin-left: 4px;
}

.input-cost-condition {
  border-radius: 4px;
  /* border: solid 1px rgba(0, 0, 0, 0.6); */
  height: 32px;
  font-size: 14px !important;
  padding: 0px 10px;
  color: rgba(0, 0, 0, 0.6);
}

/* .input-cost-condition .css-a88p61-MuiInputBase-root-MuiInput-root {
  font-size: 16px;
} */

.input-cost-condition::after {
  display: none;
}

.input-cost-condition::before {
  display: none;
}

.dentist-container-modal {
  align-items: center;
  display: flex;
}
.img-container-modal {
  width: 40px;
  height: 40px;
  background-color: black;
  border-radius: 50%;
  margin-right: 12px;
  margin-top: 0.5vw;
  align-self: flex-start;
}
.name-assistant-modal {
  display: inline-block;
}
#contained-modal-title-vcenter {
  font-weight: 700;
  font-size: 18px;
  color: #2f4269;
}
